<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="bgc sidebar">
                <div class="samplelefttit">检测项目</div>
                <div class="sidebarmain">
                    <a-tree :tree-data="node_list" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="true" @select="onSelect">
                        <a-icon slot="icon" type="carry-out"/>
                    </a-tree>
                </div>
                <div class="addbutton">
                    <div class="addbuttonbtn" @click="createClick(0)">
                        添加分类
                    </div>
                </div>
            </div>
            <div>
                <!-- 创建编辑分类 -->
                <div class="createsmaplewrap" v-show="createfenlei">
                    <div class="createsmaple1">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}分类</div>
                        <div v-if="do_type" style="margin-top: 20px; text-align: left; background: #FAFAFA; padding:10px 20px;">
                            <a-button @click="createNewList()" type="primary" class="ml20">创建分类</a-button>
                            <a-button type="primary" class="ml20" @click="createNewNode()">创建检测项目</a-button>
                            <!--                            <a-button type="danger" class="ml20" @click="delClick()">删除</a-button>-->
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick" style="float: right">
                                <template slot="title">确认删除该项？</template>
                                <a-button type="danger" class="ml20">删除</a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-left: 150px">
                            <a-form-model-item label="名称">
                                <a-input v-model="form.name" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="备注">
                                <a-textarea v-model="form.remark" class="w300"/>
                            </a-form-model-item>

                            <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                                <a-button type="primary" html-type="submit" @click="doClick2()">保存</a-button>
                            </a-form-item>
                        </a-form-model>
                    </div>
                </div>

                <!--   创建编辑 检测项目-->
                <div class="createsmaplewrap" v-show="createitem">
                    <div class="createsmaple1">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}检测项目</div>
                        <div v-if="do_type" style="margin-top: 20px; text-align: right; background: #FAFAFA; padding:10px 20px; width: 1000px">
                        <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick">
                            <template slot="title">确认删除该项？</template>
                            <a-button type="danger" class="ml20">删除</a-button>
                        </a-popconfirm>
                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form1" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-left: 150px">

                            <a-form-model-item label="名称">
                                <a-input v-model="form1.name" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="化学式">
                                <a-input v-model="form1.chemical" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="项目类型">
                                <a-select v-model="form1.factor_type" placeholder="项目类型" class="w300">
                                    <a-select-option :value="1">单因子</a-select-option>
                                    <a-select-option :value="2">多因子</a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item v-for="(item,key) in factor_arr" :key="key" v-if="Number(form1.factor_type)===2" :label="`因子${key+1}`">
                                <div style="width:120%">
                                    <a-input v-model="item.name" class="w160 mr15"/>
                                    <a-input v-model="item.chemical" class="w160 mr15"/>
                                    <a-button @click="upFClick(key)" v-if="key !== 0" type="primary" class="mr15">上移
                                    </a-button>
                                    <a-button @click="downFClick(key)" v-if="key !== factor_arr.length - 1" type="primary" class="mr15">
                                        下移
                                    </a-button>
                                    <a-button @click="delFClick(key)" type="danger">删除</a-button>
                                </div>
                            </a-form-model-item>

                            <a-form-model-item v-if="Number(form1.factor_type)===2" label="添加因子">
                                <div style="width:120%">
                                    <a-input v-model="add_factor.name" class="w160 mr15"/>
                                    <a-input v-model="add_factor.chemical" class="w160 mr15"/>
                                    <a-button @click="addFClick()" type="primary" class="mr15">添加</a-button>
                                </div>
                            </a-form-model-item>

                            <a-form-model-item label="备注">
                                <a-textarea v-model="form1.remark" class="w300"/>
                            </a-form-model-item>

                            <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                                <a-button @click="saveClick()" type="primary" html-type="submit">保存</a-button>
                            </a-form-item>


                        </a-form-model>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        props: [],
        components: {},
        data() {
            return {
                createitem: false,
                createfenlei: false,
                node_list: [],
                do_type: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                factor_arr: [],
                form: {
                    name: '',
                    region: undefined,
                },
                add_factor: {
                    id: 0,
                    name: '',
                    chemical: '',
                    sort: 0,
                },
                form1: {},
                pid: 0
            }
        },
        watch: {
            $route: {
                handler() {
                    this.Detitemlist()
                    this.mountedDo()
                },
                deep: true
            }
        },
        mounted() {
            this.Detitemlist()//获取检测项目分级列表
            this.mountedDo()

        },
        methods: {
            //
            delClick() {
                this.$sa0.post({
                    url: this.$api('Del_detitem'),
                    data: {
                        id: this.det_item.id,

                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Detitemlist()
                            this.$router.push('/jiancexiangmu/index/0')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //
            saveClick() {
                console.log('---------')
                let data = {
                    id: this.form1.id,
                    name: this.form1.name,
                    pid: this.form1.pid,
                    chemical: this.form1.chemical,
                    type: '1',
                    factor_type: this.form1.factor_type,
                    remark: this.form1.remark,

                }

                if (Number(this.form1.factor_type) === 1) {
                    data.factor = []
                } else {
                    data.factor = this.factor_arr.map((item, key) => {
                        item.sort = key
                        return item
                    })
                }

                data.factor = JSON.stringify(data.factor)
                console.log(data)

                this.$sa0.post({
                    url: this.do_type ? this.$api('Edit_detitem') : this.$api('Create_detitem'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Detitemlist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/jiancexiangmu/index/' + res.data.id)
                            }

                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            upFClick(key) {
                let u = this.factor_arr[key]
                this.$set(this.factor_arr, key, this.factor_arr[key - 1])
                this.$set(this.factor_arr, key - 1, u)
            },
            downFClick(key) {
                let u = this.factor_arr[key]
                this.$set(this.factor_arr, key, this.factor_arr[key + 1])
                this.$set(this.factor_arr, key + 1, u)
            },
            delFClick(key) {
                this.factor_arr.splice(key, 1)
            },
            addFClick() {
                this.factor_arr.push(this.add_factor)
                this.add_factor = {
                    id: 0,
                    name: '',
                    chemical: '',
                    sort: 0,
                }
            },
            //添加分类 弹出
            addfenlei() {
                this.createitem = false;
                this.createfenlei = true;
            },
            //创建检测项目 弹出
            createjiancexiangmu() {
                this.createitem = true;
                this.createfenlei = false;
            },

            //树型列表 获取检测项目分级列表
            Detitemlist() {
                this.$sa0.post({
                    url: this.$api('Detitemalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.node_list = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            onSelect(selectedKeys) {
                if (selectedKeys.length == 0) return false;
                if (Number(this.pid) === Number(selectedKeys[0])) return false;
                this.$router.push('/jiancexiangmu/index/' + selectedKeys[0])
            },

            //添加分类 文件夹
            createClick(pid) {
                this.$router.push('/jiancexiangmu/index/' + pid)
            },
            //添加分类 子项
            createNewList() {
                this.form = {
                    id: '0',
                    name: '',
                    type: '0',
                    pid: this.pid,
                    type_id: '0'
                };
                this.createfenlei = true;
                this.createitem = false;
                this.do_type = false
            },

            mountedDo() {
                this.pid = this.$route.params.id
                if (this.pid == 0) {
                    this.form = {
                        id: '0',
                        name: '',
                        type: '0',
                        pid: this.pid,
                        type_id: '0'
                    };
                    this.form1 = {
                        id: '0',
                        name: '',
                        type: '0',
                        pid: this.pid,
                        type_id: '0'
                    };
                    this.createfenlei = true;
                    this.createitem = false;
                    this.do_type = false
                } else {
                    this.getItemInfo()
                }
            },

            getItemInfo() {
                this.do_type = true
                this.$sa0.post({
                    url: this.$api('Get_detitem_detail'),
                    data: {
                        id: this.pid
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.det_item = response.data.det_item;//
                            this.openArr = [this.det_item.pid]
                            console.log(this.det_item)

                            // this.expandedKeys = ((this.item_info.form_word_ids).toString()).split(',').map((item)=>{
                            //   return Number(item)
                            // })

                            if (this.det_item.type == 0) {
                                this.createfenlei = true;
                                this.createitem = false;
                            } else {
                                this.createfenlei = false;
                                this.createitem = true;
                            }
                            this.form1 = {
                                name: this.det_item.name,
                                pid: this.det_item.pid,
                                type: this.det_item.type,
                                factor_type: this.det_item.factor_type,
                                chemical: this.det_item.chemical,
                                remark: this.det_item.remark,
                                factor: this.det_item.factor,
                                id: this.det_item.id,
                            }
                            this.form = {
                                id: this.det_item.id,
                                name: this.det_item.name,
                                pid: this.det_item.pid,
                                type: this.det_item.type,
                                factor_type: this.det_item.factor_type,
                                chemical: this.det_item.chemical,
                                remark: this.det_item.remark,
                                factor: this.det_item.factor,

                            }
                            this.factor_arr = this.det_item.factor
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            doClick2() {
                console.log(this.form.region)
                let data = {
                    id: this.do_type ? this.form.id : '0',
                    name: this.form.name,
                    pid: this.form.pid,
                    type: '0',
                    factor_type: 1,
                    remark: this.form.remark,
                    factor: JSON.stringify([])
                }
                this.$sa0.post({
                    url: this.do_type ? this.$api('Edit_detitem') : this.$api('Create_detitem'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Detitemlist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/jiancexiangmu/index/' + res.data.id)
                            }

                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            //创建检测项目
            createNewNode() {
                console.log('---createNewNode')
                this.form1 = {
                    id: '0',
                    name: '',
                    pid: this.pid,
                    type: '1',
                    factor_type: 2,
                    remark: '',
                    factor: [],
                };

                this.factor_arr = [];
                this.createfenlei = false;
                this.createitem = true;
                this.do_type = false
            },


        },
    };
</script>

<style scoped>

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 250px; display: flex; flex-direction: column}

    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex: 1; overflow-y: auto}

    .addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}

    .addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}

    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }

    .createsmaplewrap{ width: 1000px; }

    .createsmaple{ width: 800px; display: flex; justify-content: space-between}

    .createsmapletit{ font-size: 20px; font-weight: bold}


    .mt20{
        margin-top: 20px
        }

    .w300{
        width: 300px
        }

    .ml20{
        margin-left: 20px
        }

    .w160{
        width: 143px
        }

    .mr15{
        margin-right: 15px
        }

</style>
